import './App.css';
import About from './components/About';
import Home from './components/Home';
import Gallery from './components/Gallery';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Activity from './components/Activity';
import Contact from './components/Contact';
import Ebooks from './components/Ebooks';
import ScrollToTop from './components/ScrollToTop';
function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/ebooks" element={<Ebooks />} />
          <Route path="/activities" element={<Activity />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
