import React from 'react'
import QRCODE from "../assets/QRCode.jpg"
const Donation = () => {
    return (
        <div className="bg-[#4C0555] w-full h-[90vh] mb-7 flex flex-col justify-center pt-6">
            <div className="flex justify-center w-full px-4">
                {/* Added padding and full width */}
                <span className="text-xl sm:text-2xl md:text-3xl text-white font-bold text-center break-words">
                    Help with a Contribution of Your Choice
                </span>
            </div>
            <div className="flex flex-col md:flex-row justify-center align-middle p-5 md:p-7 gap-8 md:gap-35">
                <div className="flex flex-col gap-5 text-white text-center justify-center align-middle md:text-left">
                    <p className="md:text-xl font-bold">
                        Contribute Through Bank (NEFT/ RTGS)
                    </p>
                    <section className="leading-8">
                        <p>BANK NAME – PANJAB NATIONAL BANK</p>
                        <p>ACCOUNT NAME – RADHA KRISHNA GRAM VIKAS KENDRA</p>
                        <p>ACCOUNT NUMBER – 8561002100004221</p>
                        <p>IFSC CODE – PUNB0856100</p>
                    </section>
                </div>
                <div className="flex flex-col items-center justify-center text-center">
                    <img
                        src={QRCODE}
                        alt="QR CODE"
                        className="h-48 w-48 sm:h-60 sm:w-60 md:h-80 md:w-80 object-contain rounded-2xl"
                    />
                    <section className="text-white mt-4 font-bold">
                        UPI ID -7985980413m@pnb
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Donation
