import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import LOGO from '../assets/final_logo.jpg';
const Navbar = () => {
    // bg-slate-400 bg-gradient-to-r from-purple-600 to-blue-600
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    return (
        <nav className="flex items-center justify-between flex-wrap p-3 bg-[#6b195a] sticky top-0 shadow-xl z-10">
            <div className="flex items-center flex-shrink-0 text-white  ml-6 lg:mr-72 sm:mr-8">
                <Link to="/">
                    <img src={LOGO} alt="logo Hai " className='h-12 w-12 cursor-pointer rounded-full' />
                </Link>
            </div>
            <div className="block lg:hidden">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400 "
                >
                    <svg
                        className={`fill-current h-5 w-5 ${isOpen ? "hidden" : "block"}`}
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                    </svg>

                    <svg
                        className={`fill-current h-5 w-5 ${isOpen ? "block" : "hidden"}`}
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
                    </svg>
                </button>
            </div>

            <div className={`w-full block lg:flex lg:items-center lg:w-auto ${isOpen ? "block" : "hidden"}`}>
                <div className="text-xl lg:flex-grow gap-10">
                    <Link to='/' className={`block mt-4 lg:inline-block lg:mt-0 text-white mr-10 cursor-pointer ${location.pathname === '/' && !isOpen ? 'text-[#ff6600] border-b-2 border-[#ff6600]' : ''}`}>
                        HOME
                    </Link>
                    <Link to='/about' className={`block mt-4 lg:inline-block lg:mt-0 text-white mr-10 cursor-pointer ${location.pathname === '/about' && !isOpen ? 'text-[#ff6600] border-b-2 border-[#ff6600]' : ''}`}>
                        ABOUT
                    </Link>
                    <Link to='/gallery' className={`block mt-4 lg:inline-block lg:mt-0 text-white mr-10 cursor-pointer ${location.pathname === '/gallery' && !isOpen ? 'text-[#ff6600] border-b-2 border-[#ff6600]' : ''}`}>
                        GALLERY
                    </Link>
                    <Link to='/ebooks' className={`block mt-4 lg:inline-block lg:mt-0 text-white mr-10 cursor-pointer ${location.pathname === '/ebooks' && !isOpen ? 'text-[#ff6600] border-b-2 border-[#ff6600]' : ''}`}>
                        E-BOOKS
                    </Link>
                    <Link to="/activities" className={`block mt-4 lg:inline-block lg:mt-0 text-white mr-10 cursor-pointer ${location.pathname === '/activities' && !isOpen ? 'text-[#ff6600] border-b-2 border-[#ff6600]' : ''}`}>
                        ACTIVITIES
                    </Link>
                    <Link to="/contact" className={`block mt-4 lg:inline-block lg:mt-0 text-white mr-10 cursor-pointer ${location.pathname === '/contact' && !isOpen ? 'text-[#ff6600] border-b-2 border-[#ff6600]' : ''}`}>
                        CONTACT US
                    </Link>
                </div>
            </div>
        </nav>

    )
}

export default Navbar;